import c0 from './admin_date_range_controller';
import c1 from './approval_controller';
import c2 from './auto_citation_controller';
import c3 from './autocomplete_edit_controller';
import c4 from './available_date_controller';
import c5 from './checkboxes_controller';
import c6 from './clipboard_controller';
import c7 from './collection_input_controller';
import c8 from './collection_participant_form_controller';
import c9 from './complex_radio_controller';
import c10 from './contact_us_controller';
import c11 from './contributors_controller';
import c12 from './copy_controller';
import c13 from './datatable_approvals_controller';
import c14 from './datatable_collections_controller';
import c15 from './datatable_works_controller';
import c16 from './date_approximate_controller';
import c17 from './date_choice_controller';
import c18 from './date_clear_controller';
import c19 from './date_range_controller';
import c20 from './date_validation_controller';
import c21 from './decommission_controller';
import c22 from './deposit_button_controller';
import c23 from './dropzone_controller';
import c24 from './file_uploads_controller';
import c25 from './help_controller';
import c26 from './help_how_controller';
import c27 from './keywords_controller';
import c28 from './license_controller';
import c29 from './more_types_controller';
import c30 from './nested_form_controller';
import c31 from './new_user_version_controller';
import c32 from './no_newlines_controller';
import c33 from './ordered_nested_form_controller';
import c34 from './popover_controller';
import c35 from './popup_modal_controller';
import c36 from './purl_reservation_controller';
import c37 from './review_workflow_controller';
import c38 from './show_citation_controller';
import c39 from './show_controller';
import c40 from './sunet_input_controller';
import c41 from './unsaved_changes_controller';
import c42 from './work_type_controller';
import c43 from './work_type_edit_controller';
import c44 from './work_type_modal_controller';
export const definitions = [
	{identifier: 'admin-date-range', controllerConstructor: c0},
	{identifier: 'approval', controllerConstructor: c1},
	{identifier: 'auto-citation', controllerConstructor: c2},
	{identifier: 'autocomplete-edit', controllerConstructor: c3},
	{identifier: 'available-date', controllerConstructor: c4},
	{identifier: 'checkboxes', controllerConstructor: c5},
	{identifier: 'clipboard', controllerConstructor: c6},
	{identifier: 'collection-input', controllerConstructor: c7},
	{identifier: 'collection-participant-form', controllerConstructor: c8},
	{identifier: 'complex-radio', controllerConstructor: c9},
	{identifier: 'contact-us', controllerConstructor: c10},
	{identifier: 'contributors', controllerConstructor: c11},
	{identifier: 'copy', controllerConstructor: c12},
	{identifier: 'datatable-approvals', controllerConstructor: c13},
	{identifier: 'datatable-collections', controllerConstructor: c14},
	{identifier: 'datatable-works', controllerConstructor: c15},
	{identifier: 'date-approximate', controllerConstructor: c16},
	{identifier: 'date-choice', controllerConstructor: c17},
	{identifier: 'date-clear', controllerConstructor: c18},
	{identifier: 'date-range', controllerConstructor: c19},
	{identifier: 'date-validation', controllerConstructor: c20},
	{identifier: 'decommission', controllerConstructor: c21},
	{identifier: 'deposit-button', controllerConstructor: c22},
	{identifier: 'dropzone', controllerConstructor: c23},
	{identifier: 'file-uploads', controllerConstructor: c24},
	{identifier: 'help', controllerConstructor: c25},
	{identifier: 'help-how', controllerConstructor: c26},
	{identifier: 'keywords', controllerConstructor: c27},
	{identifier: 'license', controllerConstructor: c28},
	{identifier: 'more-types', controllerConstructor: c29},
	{identifier: 'nested-form', controllerConstructor: c30},
	{identifier: 'new-user-version', controllerConstructor: c31},
	{identifier: 'no-newlines', controllerConstructor: c32},
	{identifier: 'ordered-nested-form', controllerConstructor: c33},
	{identifier: 'popover', controllerConstructor: c34},
	{identifier: 'popup-modal', controllerConstructor: c35},
	{identifier: 'purl-reservation', controllerConstructor: c36},
	{identifier: 'review-workflow', controllerConstructor: c37},
	{identifier: 'show-citation', controllerConstructor: c38},
	{identifier: 'show', controllerConstructor: c39},
	{identifier: 'sunet-input', controllerConstructor: c40},
	{identifier: 'unsaved-changes', controllerConstructor: c41},
	{identifier: 'work-type', controllerConstructor: c42},
	{identifier: 'work-type-edit', controllerConstructor: c43},
	{identifier: 'work-type-modal', controllerConstructor: c44},
];
